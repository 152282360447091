:root {
  --i-icon-size: var(--prlw-size-icon-medium);
  --i-icon-color: currentcolor;
  --i-icon-rect-color: currentcolor;
  --i-icon-svg-padding: 0px;
}

.prlw-icon {
  display: flex;
  width: var(--i-icon-size);
  height: var(--i-icon-size);
}

.prlw-icon svg {
  width: var(--i-icon-size);
  height: var(--i-icon-size);
  padding: var(--i-icon-svg-padding);
}

.prlw-icon path {
  fill: var(--i-icon-color);
}

.prlw-icon rect {
  fill: var(--i-icon-rect-color);
}
