* {
  padding: 0;
  border: 0;
  margin: 0;
  background: none;
  color: inherit;
  font: inherit;
  list-style: none;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
