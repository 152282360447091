body {
  width: 100%;
  height: 100vh;
  background-color: var(--prlw-color-smoke-white);
  color: var(--prlw-color-black);
  font: var(--prlw-font-body);
}

.cdk-overlay-backdrop {
  background: var(--prlw-color-overlay-bg);
}

.grecaptcha-badge {
  visibility: hidden;
}

.highlight-positive-dynamic {
  background-color: var(--prlw-color-green);
  transition-duration: 0.5s;
}

.highlight-negative-dynamic {
  background-color: var(--prlw-color-red);
  transition-duration: 0.5s;
}

.reset-highlight-dynamic {
  background-color: transparent;
}

.prevent-select {
  user-select: none;
}
