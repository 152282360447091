:root {
  // font size
  --prlw-size-font-10: 10px;
  --prlw-size-font-12: 12px;
  --prlw-size-font-14: 14px;
  --prlw-size-font-16: 16px;
  --prlw-size-font-18: 18px;
  --prlw-size-font-20: 20px;
  --prlw-size-font-24: 24px;
  --prlw-size-font-28: 28px;
  --prlw-size-font-30: 30px;

  // font weight
  --prlw-weight-font-normal: 400;
  --prlw-weight-font-medium: 500;
  --prlw-weight-font-semi-bold: 600;
  --prlw-weight-font-bold: 700;

  // padding
  --prlw-size-viewport-padding: 10px;

  // gap
  --prlw-size-panes-gap: 10px;
  --prlw-size-table-grid-gap: 50px;

  // height
  --prlw-size-header-height: 50px;
  --prlw-size-table-header-height: 40px;
  --prlw-size-table-row-height: 45px;

  // width
  --prlw-size-sidebar-width: 54px;

  // border-radius
  --prlw-size-border-radius: 30px;
  --prlw-size-button-border-radius: 44px;

  // icon
  --prlw-size-icon-small: 16px;
  --prlw-size-icon-medium: 24px;
  --prlw-size-icon-large: 32px;

  // grid
  --prlw-contracts-table-grid-template: 230px 170px 145px 145px 145px 145px
    145px 145px 145px;
  --prlw-size-table-basis-overlay-grid-template: 40px 180px 282px 212px;
  --prlw-size-profile-license-table: minmax(110px, 0.4fr) minmax(110px, 0.4fr)
    minmax(75px, 0.4fr) minmax(370px, 1fr) minmax(300px, 300px);
}
