:root {
  // common project colors
  --prlw-color-white: #fff;
  --prlw-color-smoke-white: #f5f5f5;
  --prlw-color-light-gray: #e5e5e5;
  --prlw-color-dim-gray: 666666;
  --prlw-color-green: #13ec55;
  --prlw-color-lime: #00ff47;
  --prlw-color-red: #f00;
  --prlw-color-black: #000;

  // нужно по максимуму объединить и переименовать
  --prlw-color-data-field-bg: #fafafa;
  --prlw-color-delivery-time: #0ff;
  --prlw-color-border: #efefef;
  --prlw-color-border-hover: #c7c7c7;
  --prlw-color-tooltip-label: #cecece;
  --prlw-color-scroll: #babac0;
  --prlw-color-placholder: #8a8a8a;
  --prlw-color-tabs-input-border: #979797;
  --prlw-color-disabled: #bdbdbd;
  --prlw-color-label-fg: #a0a0a0;
  --prlw-color-dark-grey: #666;
  --prlw-color-table-header-indicator-deal: #2f80ed;
  --prlw-color-table-content-deal: #006dff;
  --prlw-color-contract-status-terminated: #fa00ff;
  --prlw-color-recaptcha-text: #00f;
  --prlw-color-demo-period: #00e979;
  --prlw-color-table-content-demand: #219653;
  --prlw-color-table-content-deal-last-session: #4f4f4f;

  // другой формат
  --prlw-color-transparent: transparent;
  --prlw-color-overlay-shadow: 0 0 12px rgb(0 0 0 / 25%);
  --prlw-color-overlay-bg: rgb(0 0 0 / 60%);
  --prlw-color-table-highlight: rgba(255 204 0 / 40%);

  // refactor this
  --prlw-color-profile-alert: #fff9e2;
  --prlw-color-profile-file-upload-success: #e2ffe7;
  --prlw-color-profile-documents-overlay-bg: #f7f8fb;
  --prlw-color-profile-license-history-invoice: #cd57eb;
}
