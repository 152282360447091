@import 'reset';
@import 'fonts';
@import 'colors';
@import 'sizes';
@import 'common';
@import '../libs/icons/icons.global';
@import '../libs/dynamic-input-placeholder/dynamic-input-placeholder.global';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~@angular/cdk/a11y-prebuilt.css';
@import '~nouislider/dist/nouislider.min.css';
@import 'customToastr';
@import 'material';
@import 'mobile-overlay';
